import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Rachel Caldwell" subtitle="Director" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "648px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABcBAQEBAQAAAAAAAAAAAAAAAAAEAgP/2gAMAwEAAhADEAAAAYnzqx0xEB95q655CsP/xAAbEAACAgMBAAAAAAAAAAAAAAABAgAREiEiA//aAAgBAQABBQI6jKympfXseMooslbDLRc9ZasT/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQESGB/9oACAEDAQE/AYTrDp//xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIQESFR/9oACAECAQE/AalFPDRrh//EABwQAAEDBQAAAAAAAAAAAAAAAAABAhARITHB4f/aAAgBAQAGPwIo9IQ7O4apYwf/xAAcEAEAAwEBAAMAAAAAAAAAAAABACExEVFBgZH/2gAIAQEAAT8ho9fiDw8cTI+Q5Nr7Ogb2tTolLiZHvCnEekqIEfqX7H7Htfc//9oADAMBAAIAAwAAABCIyoL/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Qy2UKZHZ//8QAGREAAwADAAAAAAAAAAAAAAAAAAERITGh/9oACAECAQE/ECMaoQWGvD//xAAfEAEAAgICAgMAAAAAAAAAAAABABEhQTFRYXGR0fH/2gAIAQEAAT8Qwt9FNrgnAyGWvT3DHMpi8RA40A88w1hobOTzXqBAHWYNY5irqAgTUoU2Ys+ZXrkWNfUQEFJ0x+xq1lLDQEh1Stqz/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/01.jpg",
              "srcSet": ["/static/aab8fa1fe87bdae2c110628bd148f522/f93b5/01.jpg 300w", "/static/aab8fa1fe87bdae2c110628bd148f522/b4294/01.jpg 600w", "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/01.jpg 648w"],
              "sizes": "(max-width: 648px) 100vw, 648px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`This was a school year to remember; and not always for the most positive of reasons. That said, it is important to focus on the gains and the progress made by our school, students and staff during this extraordinarily challenging time.`}</p>
    </blockquote>
    <p>{`There is no doubt that we have all learned a great deal from the ongoing disruptions caused by the pandemic. Indeed, I have heard many educators describe 2020 and 2021 as the most powerful years of learning in their lives; this is certainly true for me. The amount of collaborative problem-solving required to navigate all of the seemingly unending and unpredictable challenges was no less than astounding. I thank our teachers and teaching assistants for their extraordinary commitment and adaptability. It has been demanding, eventful, exhausting and unpredictable, but they have managed themselves with professionalism, with determination, resilience and aplomb. Our students also made great strides and, in addition to the usual achievements they:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`know now that they have the ability to endure, and to be strong in the face of uncertainty and disappointment...they are resilient`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`have learned to face difficulties, with courage, with tenacity, and often with a good dose of humour`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`have learned that in tough times it is possible to find good solutions, to use their voice and to problem solve creatively`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`have learned appreciation for things in their daily lives, in their daily routines, things like morning greetings, a noisy campus, a handshake or a pat on the back, having lunch with friends, gathering together to talk and laugh, enjoying sports together, enjoying drama productions together, singing together, giving a friend hug`}</p>
      </li>
    </ul>
    <p>{`They have also learned the true and high value of kindness which they extended to each other, to their teachers, to me. Their words of care and encouragement have meant a lot and have helped to keep us motivated and true to our purpose. Student voice at PSI continues to become clearer, more articulate and more confident.`}</p>
    <p>{`I would like to share my thanks to our Parent Teacher Association, led by Tawnia Sandford Ammar. Tawnia’s calm, empathetic and focused approach makes her a pleasure to work with. Tawnia and the whole PTA team have shown great determination this year; determination to continue to support and build community. From regular parent tours of campus over weekends to wonderful events such as the ‘Magic Kindness Holiday Zoom-a-thon’, our PTA has been with us throughout this demanding year.`}</p>
    <p>{`Before I close, I would like to acknowledge and thank two very special individuals departing PSI. Primary Principal, Sue Williams leaves us after 4 years of service. Sue’s energy and commitment have brought us much benefit. The literacy programme in the Primary School is flourishing and Readers and Writers Workshop is now well established. We wish Sue well in her new position. Also completing their time at our school is Alex Munteanu. Alex provided wisdom and guidance to the PSI community as its Board Chair for the last 3 years, and was a source of significant strength during this period of crisis. He will be missed.`}</p>
    <p>{`As we honour the past, we look forward, with great excitement, to the future and we welcome Jan Humpleby as our new Primary Principal and Peter Erben as our Board Chair.`}</p>
    <p>{`With that, it is with pride that I present to you all the 2020 / 2021 Annual Report.`}</p>
    <p><strong parentName="p">{`Sincerely,`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`Rachel Caldwell`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Director`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Pechersk School International, Kyiv, Ukraine`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      